import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import PatekCategoryBtns from '../../components/preowned/PatekCategoryBtns'

// markup
const PatekWorldTime = () => {
  const data = useStaticQuery(
    graphql`
      query queryPatekWorldTime {
        products: allStrapiProduct(
          filter: {
            brand: { eq: "Patek Philippe" }
            model: { regex: "/World Time/" }
            qty: { gt: 0 }
          }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Patek Philippe World Time Watches for Sale'}
      canonical={'/fine-watches/patek-philippe/world-time/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Patek Philippe World Time Watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/patek-philippe/filter/model/world-time/">
              <StaticImage
                src="../../images/preowned/preowned-patek-philippe-world-time-banner.png"
                alt="Pre-Owned Certified Used Patek Philippe World Time Watches Header"
                aria-label="Used Patek Philippe World Time Image Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 lg:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">
              FIND PRE-OWNED, USED, VINTAGE, ANTIQUE, HEIRLOOM, ESTATE PATEK PHILIPPE WORLD TIME
              WATCHES AT GRAY AND SONS
            </h1>
            <h2>
              BUY, SELL, TRADE, AND REPAIR USED AND PRE OWNED PATEK PHILIPPE WORLD TIME WATCHES WITH
              GRAY AND SONS JEWELERS
            </h2>
            <p>
              We are a real watch and jewelry store located in Surfside, Miami. We're located across
              from the famous Bal Harbour Shops, with real watch and jewelry experts on-site, six
              days a week. We have a website but we're not a virtual store. Our customers always say
              we are Miami's #1 used watch buyer and seller. We've proudly assisted customers for
              the past 42 years. Gray and Sons are sellers and buyers of pre-owned Patek Philippe
              World Time watches in Miami, Miami Beach, Coral Gables, Coconut Grove, South Beach,
              Bal Harbour, Surfside, Sunny Isles , Aventura, Fort Lauderdale and many more areas.
              <br />
              <br />
              Gray and Sons is the best place to go if you want to sell your Patek Philippe World
              Time Watch. We are the best place to come if you are in the market to buy a pre-owned
              Patek Philippe World Time Watch too. Would you like to MAKE AN OFFER on watches and
              jewelry? Rich is a live Patek Philippe Watch expert decision makers and ready to work
              with you now. Click <a href="/chat-with-rich">Here.</a> We offer certified pre-owned watches.
              <br />
              <br />
              <b>About Patek Philippe World Time Watches</b>
              <br />
              <br />
              After watchmaker Louis Cottier invented the world time complication in 1931, the first
              Patek Philippe world time watch (known as the Heures Universelles) was released soon
              after, in 1939. That was Reference 1415, which was in production until 1952. Reference
              2523 joined in 1953 and remained in production until 1965. Perhaps surprising to some,
              Patek Philippe did not produce any world time watches from then until 2000, when the
              modern World Time made its debut.
              <br />
              <br />
              A modern Patek Philippe World Time watch allows wearers to read 24 time zones at once
              thanks to a system that incorporates a disk with major cities representing each time
              zone and a 24-hour scale. Patek World Time watches are not only some of the most
              complicated timepieces from the brand but also some of the most collectible.
              <br />
              <br />
              <b>Buying Pre-Owned Patek Philippe World Time Watches</b>
              <br />
              <br />
              While vintage Patek Philippe World Time watches are exceedingly rare to find, modern
              versions can be easier to buy. Pre-owned Patek Philippe World Time watches are
              available in an assortment of metals and sizes, and there are even models that combine
              the world time complication with other complications. When you want to buy a Patek
              Philippe World Time watch, visit <a href="/">www.grayandsons.com </a>
              for the best prices and the best selection.
              <br />
              <br />
              For instance, you can find pre-owned Patek Philippe World Time Chronograph watches,
              used Patek Philippe World Time Moon Phase watches, and secondhand Patek Philippe World
              Time Minute Repeater watches in the secondary market. World Time references with the
              decorative cloisonné enamel dials are particularly special and cherished by
              collectors.
              <br />
              <br />
              If you’re looking to sell a pre-owned Patek Philippe World Time watch, make sure to
              contact Gray &amp; Sons to get the best deal in the market. Popular Pre-Owned Patek
              Philippe World Time References are Reference 5510, Reference 5130, Reference 5131,
              Reference 7130, Reference 5575, Reference 7175, Reference 5230, Reference 5930,
              Reference 5531, and Reference 5231.
              <br />
              <br />
              <b>Servicing Pre-Owned Patek Philippe World Time Watches</b>
              <br />
              <br />
              Due to their complex movements, exquisite finishing, and intricate dials, the repair
              or servicing of a used Patek World Time watch should only be done by a highly-skilled
              watchmaker. Gray &amp; Sons is home to a world-class watch repair facility with a team
              of watchmakers who are expertly trained to service fine Swiss watches, such as the
              World Time by Patek Philippe.
              <br />
              <br />
              When you are ready to sell your antique pre-owned Patek Philippe World Time timepiece
              watch, take it to Gray and Sons, in Surfside, Miami Beach, Florda or visit our
              purchasing website at
              <a href="https://sellusyourjewelry.com"> www.sellusyourjewelry.com</a>
              . Our customers think we are the #1 watch repair service center for Patek Philippe
              watches. With our Swiss trained watch makers and 42 years in the industry making our
              watch collectors feel happy. Our customers trust us with their retro vintage heirloom
              family watches. Come see us for yourself.
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/fine-watches/patek-philippe/filter/model/world-time/">
                <button>SHOP PATEK PHILIPPE WORLD TIME WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK PATEK PHILIPPE WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <PatekCategoryBtns />

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/drvX30qLcgg'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default PatekWorldTime
